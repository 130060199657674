import { Link, useLocation, useNavigate } from "react-router-dom";
import { cn } from "@/lib/utils.js";
import {
  ChartNetwork,
  ChartPieIcon,
  CpuIcon,
  Eye,
  EyeOff,
  FactoryIcon,
  Lock,
  LockOpen,
  TrendingDown,
} from "lucide-react";
import LotiLogo from "../../assets/Logo.jsx";
import { Button } from "../ui/button.jsx";
import updateRateStore from "../common/updateRateStore.js";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTrigger,
} from "@/components/ui/dialog";
import { useState } from "react";
import { Label } from "../ui/label.jsx";
import { Input } from "../ui/input.jsx";
import bcrypt from "bcryptjs";
import { toast } from "react-toastify";

const Navbar = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  const navigate = useNavigate();
  const isAuth = updateRateStore((state) => state.isAuth);
  const isUnlocked = updateRateStore((state) => state.isUnlocked);
  const toggleUnlocked = updateRateStore((state) => state.toggleUnlocked);

  const [lockOpen, setLockOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const navItems = [
    ...(isAuth
      ? [
          {
            name: "Data Pipeline",
            link: "/dashboard",
            icon: <FactoryIcon className={"w-[18px]"} />,
          },
          {
            name: "GPU Fleet",
            link: "/gpu-fleet",
            icon: <CpuIcon className={"w-[18px]"} />,
          },
          {
            name: "Post Processing",
            link: "/post-processing",
            icon: <ChartNetwork className={"w-[19px]"} />,
          },
        ]
      : []),
    {
      name: "Takedowns",
      link: "/",
      icon: <TrendingDown className={"w-[19px]"} />,
    },
    {
      name: "Public Stats",
      link: "/public-stats",
      icon: <ChartPieIcon className={"w-[19px]"} />,
    },
  ];

  const handlePasswordSubmit = () => {
    bcrypt.compare(
      password,
      "$2a$10$slzcSrCQXdxi5mOStzDxE.FbHuRzXXljtZjjscprUJIv3BGcdFwhu",
      function (err, result) {
        if (!result) {
          toast.error("Incorrect password.");
        } else {
          setLockOpen(false);
          updateRateStore.setState({ isAuth: true, isUnlocked: true });
          toast.success("Unlocked!");
        }
      }
    );
    setPassword("");
  };

  const changeLockIcon = () => {
    if (isUnlocked) {
      toggleUnlocked(false);
      if (isAuth === true) {
        updateRateStore.setState({ isAuth: false });
        navigate("/");
      }
      setLockOpen(false);
    } else {
      setLockOpen(true);
    }
  };

  const handleDialogOpenChange = (open) => {
    if (!isUnlocked && open) {
      setLockOpen(true);
    } else {
      setLockOpen(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handlePasswordSubmit();
    }
  };

  return (
    <div
      className={
        "w-full h-full flex items-center gap-10 px-20 font-medium bg-navbar text-background"
      }
    >
      <div className={"flex items-center justify-between"}>
        <div className={"h-full flex items-center justify-center"}>
          <LotiLogo fill={"#079B48"} />
        </div>
      </div>
      <div className={"w-full flex items-center justify-between"}>
        <div className={"flex"}>
          {navItems.map((item) => (
            <div
              key={item.link}
              className={"p-2 rounded-lg text-background text-sm"}
            >
              <Link
                to={item.link}
                className={cn(
                  "flex gap-2 items-center px-4 py-2 rounded-lg font-normal hover:bg-muted-navbar transition-all duration-300 ease-in-out",
                  {
                    "bg-muted-navbar": currentPath === item.link,
                  }
                )}
              >
                <p>{item.icon}</p>
                <p>{item.name}</p>
              </Link>
            </div>
          ))}
        </div>
        <div>
          <Dialog open={lockOpen} onOpenChange={handleDialogOpenChange}>
            <DialogTrigger>
              <Button
                className={cn(
                  "bg-blue-500 hover:bg-blue-500/80 text-white rounded-full font-light py-3 px-3",
                  "transition-all duration-300 ease-in-out"
                )}
                onClick={changeLockIcon}
              >
                {isUnlocked ? (
                  <LockOpen className="w-5 h-5" />
                ) : (
                  <Lock className="w-5 h-5" />
                )}
              </Button>
            </DialogTrigger>
            <DialogContent className="w-[30%]">
              <div className="grid gap-4 py-4">
                <div className="flex flex-col items-start gap-3">
                  <Label htmlFor="password" className="text-right">
                    Password
                  </Label>
                  <Input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    value={password}
                    onKeyDown={handleKeyDown}
                    onChange={(e) => setPassword(e.target.value)}
                    className="col-span-3"
                  />

                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute mt-9 right-9 flex items-center"
                  >
                    {showPassword ? (
                      <Eye className="w-5 h-5 text-gray-500" />
                    ) : (
                      <EyeOff className="w-5 h-5 text-gray-500" />
                    )}
                  </button>
                </div>
              </div>

              <DialogFooter>
                <Button type="submit" onClick={handlePasswordSubmit}>
                  Submit
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
