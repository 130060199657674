import { all, put, takeLatest, call } from "redux-saga/effects";
import * as actionTypes from "@/redux/Dashboard/actionTypes.js";
import API from "@/axios/axios.js";

function* fetchDashboardCounts() {
  try {
    const [processorResponse, scraperResponse] = yield all([
      call(API.get, "/processor/dashboard"),
      call(API.get, "/scraper/get"),
    ]);

    if (processorResponse.data && scraperResponse.data) {
      const combinedData = {
        processor: processorResponse.data.data,
        scraper: scraperResponse.data.data,
      };

      yield put({
        type: actionTypes.GET_DASHBOARD_COUNTS_SUCCESS,
        payload: combinedData,
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_DASHBOARD_COUNTS_FAILURE,
      payload: error?.message,
    });
  }
}

function* fetchGraphData() {
  try {
    const [scrapedResponse, indexedResponse, processorResponse] = yield all([
      call(API.get, "/scraper/graph?days=15"),
      call(API.get, "/scraper/index-graph?days=15"),
      call(API.get, "/scraper/processed-graph?days=15"),
    ]);

    if (indexedResponse.data && scrapedResponse.data) {
      const combinedData = {
        indexed: indexedResponse.data.data,
        scraped: scrapedResponse.data.data,
        processed: processorResponse.data.data,
      };

      yield put({
        type: actionTypes.GET_GRAPH_DATA_SUCCESS,
        payload: combinedData,
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_GRAPH_DATA_FAILURE,
      payload: error?.message,
    });
  }
}

function* dashboardSaga() {
  yield all([
    takeLatest(actionTypes.GET_DASHBOARD_COUNTS, fetchDashboardCounts),
    takeLatest(actionTypes.GET_GRAPH_DATA, fetchGraphData),
  ]);
}

export default dashboardSaga;
