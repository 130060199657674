import Dashboard from "@/pages/Dashboard/Dashboard.jsx";
import Live from "@/pages/Live/Live.jsx";
import Processor from "@/pages/Processor/Processor";
import UserComparison from "@/pages/UserComparison/UserComparison";
import TakedownStats from "@/pages/TakedownStats/TakedownStats.jsx";
import PostProcessingNew from "@/pages/PostProcessing/PostProcessingNew";

const routes = [
  {
    path: "/",
    component: TakedownStats,
  },
  {
    path: "/post-processing",
    component: PostProcessingNew,
  },
  {
    path: "/dashboard",
    component: Dashboard,
  },
  {
    path: "/processor",
    component: Processor,
  },
  {
    path: "/gpu-fleet",
    component: Live,
  },
  {
    path: "/public-stats",
    component: UserComparison,
  },
];
export default routes;
