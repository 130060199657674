export const GET_POST_PROCESSING_DATA = "GET_POST_PROCESSING_DATA";
export const GET_POST_PROCESSING_DATA_SUCCESS =
  "GET_POST_PROCESSING_DATA_SUCCESS";
export const GET_POST_PROCESSING_DATA_FAILURE =
  "GET_POST_PROCESSING_DATA_FAILURE";

export const GET_POST_PROCESSING_INSTANCE_DATA =
  "GET_POST_PROCESSING_INSTANCE_DATA";
export const GET_POST_PROCESSING_INSTANCE_DATA_SUCCESS =
  "GET_POST_PROCESSING_INSTANCE_DATA_SUCCESS";
export const GET_POST_PROCESSING_INSTANCE_DATA_FAILURE =
  "GET_POST_PROCESSING_INSTANCE_DATA_FAILURE";

export const PROCESSING_DATA = "PROCESSING_DATA";
export const PROCESSING_DATA_SUCCESS = "PROCESSING_DATA_SUCCESS";
export const PROCESSING_DATA_FAILURE = "PROCESSING_DATA_FAILURE";

export const UPDATE_INSTANCE_CAPACITY = "UPDATE_INSTANCE_CAPACITY";
export const UPDATE_INSTANCE_CAPACITY_SUCCESS =
  "UPDATE_INSTANCE_CAPACITY_SUCCESS";
export const UPDATE_INSTANCE_CAPACITY_FAILURE =
  "UPDATE_INSTANCE_CAPACITY_FAILURE";

export const UPDATE_BATCH_N_STATUS = "UPDATE_BATCH_N_STATUS";
export const UPDATE_BATCH_N_STATUS_SUCCESS = "UPDATE_BATCH_N_STATUS_SUCCESS";
export const UPDATE_BATCH_N_STATUS_FAILURE = "UPDATE_BATCH_N_STATUS_FAILURE";
