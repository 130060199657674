import * as actionTypes from "./actionTypes.js";

export const getPostProcessing = () => ({
  type: actionTypes.GET_POST_PROCESSING_DATA,
});

export const getPostProcessingInstances = () => ({
  type: actionTypes.GET_POST_PROCESSING_INSTANCE_DATA,
});

export const getProcessingData = () => ({
  type: actionTypes.PROCESSING_DATA,
});

export const updateInstanceCapacity = (payload) => ({
  type: actionTypes.UPDATE_INSTANCE_CAPACITY,
  payload,
});

export const updateBatchNStatus = (payload) => ({
  type: actionTypes.UPDATE_BATCH_N_STATUS,
  payload,
});
