import usePostProcessingStore from "@/pages/PostProcessing/postProcessingStore.js";
import * as actionTypes from "./actionTypes.js";

const initialState = {
  loading: false,
  data: {},
  error: null,
  processingData: {},
  processingLoading: false,
  instanceLoading: false,
  instanceError: null,
  instanceData: {},
  batchNStatus: {},
  batchNStatusError: null,
  batchNStatusLoading: false,
};

const postProcessingReducer = (state = initialState, action) => {
  const postProcessingStore = usePostProcessingStore.getState();
  switch (action.type) {
    case actionTypes.GET_POST_PROCESSING_DATA:
      postProcessingStore.setLoading(true);
      return { ...state, loading: true, error: null };

    case actionTypes.GET_POST_PROCESSING_DATA_SUCCESS:
      postProcessingStore.setPostProcessingData(action.payload);
      postProcessingStore.setLoading(false);
      return {
        ...state,
        loading: false,
        processingData: action.payload,
        error: null,
      };

    case actionTypes.GET_POST_PROCESSING_DATA_FAILURE:
      postProcessingStore.setLoading(false);
      return { ...state, loading: false, error: action.error };

    case actionTypes.PROCESSING_DATA:
      postProcessingStore.setProcessingLoading(true);
      return { ...state, processingLoading: false, error: null };

    case actionTypes.PROCESSING_DATA_SUCCESS:
      postProcessingStore.setProcessingData(action.payload);
      postProcessingStore.setProcessingLoading(false);
      return {
        ...state,
        processingLoading: false,
        processingData: action.payload,
        error: null,
        batchNStatusLoading: false,
      };

    case actionTypes.PROCESSING_DATA_FAILURE:
      postProcessingStore.setProcessingLoading(false);
      return { ...state, processingLoading: false, error: action.error };

    case actionTypes.GET_POST_PROCESSING_INSTANCE_DATA:
      postProcessingStore.setLoading(true);
      return { ...state, loading: false, error: null };

    case actionTypes.GET_POST_PROCESSING_INSTANCE_DATA_SUCCESS:
      postProcessingStore.setPostProcessingInstanceData(action.payload);
      postProcessingStore.setLoading(false);
      postProcessingStore.setInstanceLoading(false);
      return {
        ...state,
        loading: false,
        instanceData: action.payload,
        error: null,
      };

    case actionTypes.GET_POST_PROCESSING_INSTANCE_DATA_FAILURE:
      postProcessingStore.setLoading(false);
      return { ...state, loading: false, error: action.error };

    case actionTypes.UPDATE_INSTANCE_CAPACITY:
      postProcessingStore.setInstanceLoading(true);
      return { ...state, instanceLoading: true, instanceError: null };

    case actionTypes.UPDATE_INSTANCE_CAPACITY_SUCCESS:
      postProcessingStore.setInstanceData(action.payload);
      return {
        ...state,
        instanceLoading: false,
        instanceError: null,
        instanceData: action.payload,
      };

    case actionTypes.UPDATE_INSTANCE_CAPACITY_FAILURE:
      postProcessingStore.setInstanceLoading(false);
      return { ...state, instanceLoading: false, instanceError: action.error };

    case actionTypes.UPDATE_BATCH_N_STATUS:
      postProcessingStore.setBatchNStatusLoading(true);
      return { ...state, batchNStatusLoading: true, batchNStatusError: null };

    case actionTypes.UPDATE_BATCH_N_STATUS_SUCCESS:
      postProcessingStore.setBatchNStatus(action.payload);
      return {
        ...state,
        batchNStatusError: null,
        batchNStatus: action.payload,
      };

    case actionTypes.UPDATE_BATCH_N_STATUS_FAILURE:
      postProcessingStore.setBatchNStatusLoading(false);
      return {
        ...state,
        batchNStatusLoading: false,
        batchNStatusError: action.error,
      };

    default:
      return state;
  }
};

export default postProcessingReducer;
