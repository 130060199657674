import { Skeleton } from "@/components/ui/skeleton.jsx";

export default function SkeletonWrapper({
  count,
  height,
  width,
  className,
  skeletonClass,
}) {
  const skeletonArray = Array.from({ length: count });
  return (
    <div className={className}>
      {skeletonArray.map((_, index) => (
        <Skeleton
          key={index}
          style={{ height: height, width: width, marginBottom: "0.5rem" }}
          className={skeletonClass}
        />
      ))}
    </div>
  );
}
