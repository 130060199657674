import {
  getPostProcessing,
  getPostProcessingInstances,
  getProcessingData,
  updateBatchNStatus,
  updateInstanceCapacity,
} from "@/redux/PostProcessing/actions";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import usePostProcessingStore from "./postProcessingStore";
import Charts from "@/components/common/Charts/Charts";
import SkeletonWrapper from "@/components/common/Wrappers/Skeleton/SkeletonWrapper";
import { formatDate, formateValue } from "@/lib/utils";
import TooltipWrapper from "@/components/common/Wrappers/Tooltip/TooltipWrapper";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { DualRangeSlider } from "@/components/ui/range-slider";
import { Switch } from "@/components/ui/switch";
import { Info, Pencil, Server, TrendingUpDownIcon } from "lucide-react";
import { Input } from "@/components/ui/input";
import Loader from "@/components/ui/loader";
import ReactFlow, {
  applyNodeChanges,
  Background,
  Handle,
  MarkerType,
  Position,
} from "reactflow";
import "reactflow/dist/style.css";

const transformData = (data) => {
  if (!data || typeof data !== "object") {
    return [];
  }

  return Object.keys(data)
    .map((date) => {
      const { ...rest } = data[date];
      return { date, ...rest };
    })
    .reverse();
};

const cards = [
  {
    id: "milvus-search",
    position: { x: -400, y: -76 },
    type: "selectorNode",
    data: {
      key: {
        id: "milvus-search",
        processingKey: "Milvus-search",
        label: "Milvus Search",
        outputPoint: Position.Right,
      },
      index: 0,
    },
    sourcePosition: Position.Right,
  },
  {
    id: "loti-post-media-processing-frame-downloader-asg",
    type: "selectorNode",
    position: { x: 100, y: -100 },
    targetPosition: Position.Left,
    data: {
      key: {
        id: "loti-post-media-processing-frame-downloader-asg",
        processingKey: "frame-download",
        label: "Frame Download",
        inputPoint: Position.Left,
        outputPoint: Position.Right,
      },
      index: 1,
    },
    sourcePosition: Position.Bottom,
  },
  {
    id: "loti-post-media-processing-deepfake-checker-asg",
    data: {
      key: {
        id: "loti-post-media-processing-deepfake-checker-asg",
        processingKey: "deepfake",
        label: "Deepfake",
        inputPoint: Position.Left,
        outputPoint: Position.Right,
      },
      index: 3,
    },
    position: { x: 600, y: -300 },
    type: "selectorNode",
    targetPosition: Position.Left,
  },
  {
    id: "loti-media-post-processing-image-classification-asg",
    type: "selectorNode",
    position: { x: 600, y: 100 },
    data: {
      key: {
        id: "loti-media-post-processing-image-classification-asg",
        processingKey: "classification",
        label: "Classification",
        inputPoint: Position.Left,
        outputPoint: Position.Right,
      },
      index: 2,
    },
    targetPosition: Position.Left,
    sourcePosition: Position.Right,
  },
  {
    id: "loti-media-post-processing-threat-level-asg",
    data: {
      key: {
        id: "loti-media-post-processing-threat-level-asg",
        processingKey: "threat-level",
        label: "Threat Score",
        inputPoint: Position.Left,
      },
      index: 4,
    },
    position: { x: 1200, y: -100 },
    type: "selectorNode",
    targetPosition: Position.Left,
  },
];

const edge = [
  {
    id: "milvus-search-loti-post-media-processing-frame-downloader-asg",
    source: "milvus-search",
    target: "loti-post-media-processing-frame-downloader-asg",
    animated: true,
    style: {
      strokeWidth: 2,
      stroke: "#079B48",
    },
    markerEnd: {
      type: MarkerType.ArrowClosed,
      width: 20,
      height: 20,
      color: "#079B48",
    },
  },
  {
    id: "loti-post-media-processing-frame-downloader-asg-loti-post-media-processing-deepfake-checker-asg",
    source: "loti-post-media-processing-frame-downloader-asg",
    target: "loti-post-media-processing-deepfake-checker-asg",
    animated: true,
    style: {
      strokeWidth: 2,
      stroke: "#079B48",
    },
    sourceHandle: "a",
    markerEnd: {
      type: MarkerType.ArrowClosed,
      width: 20,
      height: 20,
      color: "#079B48",
    },
  },
  {
    id: "loti-post-media-processing-frame-downloader-asg-loti-media-post-processing-image-classification-asg",
    source: "loti-post-media-processing-frame-downloader-asg",
    target: "loti-media-post-processing-image-classification-asg",
    animated: true,
    style: {
      strokeWidth: 2,
      stroke: "#079B48",
    },
    sourceHandle: "b",
    markerEnd: {
      type: MarkerType.ArrowClosed,
      width: 20,
      height: 20,
      color: "#079B48",
    },
  },
  {
    id: "loti-media-post-processing-image-classification-asg-loti-media-post-processing-threat-level-asg",
    source: "loti-media-post-processing-image-classification-asg",
    target: "loti-media-post-processing-threat-level-asg",
    animated: true,
    style: {
      strokeWidth: 2,
      stroke: "#079B48",
    },
    markerEnd: {
      type: MarkerType.ArrowClosed,
      width: 20,
      height: 20,
      color: "#079B48",
    },
  },
  {
    id: "loti-post-media-processing-deepfake-checker-asg-loti-media-post-processing-threat-level-asg",
    source: "loti-post-media-processing-deepfake-checker-asg",
    target: "loti-media-post-processing-threat-level-asg",
    animated: true,
    targetHandle: "c",
    style: {
      strokeWidth: 2,
      stroke: "#079B48",
    },
    markerEnd: {
      type: MarkerType.ArrowClosed,
      width: 20,
      height: 20,
      color: "#079B48",
    },
  },
];

const PostProcessingNew = () => {
  const [nodes, setNodes] = useState(cards);
  const [openInstances, setOpenInstances] = useState({});
  const [instanceModalOpen, setInstanceModalOpen] = useState({
    isOpen: false,
    instance: null,
    value: [2],
  });
  const [batchModalOpen, setBatchModalOpen] = useState({
    isOpen: false,
    value: 0,
    instance: null,
    key: "",
  });
  const dispatch = useDispatch();
  const {
    postProcessingData,
    postProcessingInstanceData,
    processingData,
    processingInstanceHistory,
    processingLoading,
    postProcessingInstanceLoading,
    instanceLoading,
    batchNStatusLoading,
    setProcessData,
  } = usePostProcessingStore((state) => state);

  const renderInstanceCard = (key, index) => {
    if (key.id === "milvus-search") {
      return (
        <div key={index} className="w-full min-w-[400px] relative">
          <div className="flex items-center">
            <div className="w-full border-border bg-white border rounded-xl shadow-lg py-4 px-6 transition-all duration-300 hover:shadow-xl">
              <div className="flex gap-3 justify-between items-center mb-4">
                <h4 className="text-sm text-[#777777] font-semibold">
                  {key.label}
                </h4>
                <div className="flex gap-1 xl:gap-2 items-center">
                  {!processingData?.data || batchNStatusLoading ? (
                    <SkeletonWrapper
                      width="100px"
                      height="30px"
                      count={1}
                      skeletonClass="rounded-full"
                    />
                  ) : (
                    <div className="flex gap-1 items-center text-sm text-muted-foreground">
                      Status: (
                      {processingData?.data?.["search-milvus"]?.status ===
                      "true"
                        ? "Active"
                        : "InActive"}
                      <Switch
                        checked={
                          processingData?.data?.["search-milvus"]?.status ===
                          "true"
                        }
                        onCheckedChange={(checked) => {
                          handleUpdateStatus({
                            value: checked,
                            key: "search-milvus",
                          });
                        }}
                        className="data-[state=checked]:bg-green-600"
                      />
                      )
                    </div>
                  )}
                </div>
              </div>
              <div className="grid gap-2 grid-cols-2">
                <div className="relative border-r border-border w-full">
                  <h5 className="text-lg xl:text-2xl font-bold text-gray-800">
                    {!processingData?.data || processingLoading ? (
                      <SkeletonWrapper width="90px" height="30px" count={1} />
                    ) : (
                      processingData?.data &&
                      processingData?.data["search-milvus"]?.counts
                    )}
                  </h5>
                  <p className="text-[10px] xl:text-xs text-gray-400 font-normal">
                    Remaining
                  </p>
                </div>
                <div className="relative w-full">
                  <h5 className="text-lg xl:text-2xl font-bold">
                    {!processingData?.data ||
                    processingLoading ||
                    batchNStatusLoading ? (
                      <SkeletonWrapper width="70px" height="30px" count={1} />
                    ) : (
                      <div className="flex gap-2 items-center">
                        {processingData?.data &&
                          processingData?.data["search-milvus"]?.batch_size}
                        <Pencil
                          onClick={() =>
                            setBatchModalOpen({
                              isOpen: true,
                              instance: null,
                              value: processingData?.data
                                ? processingData?.data["search-milvus"]
                                    ?.batch_size
                                : 0,
                              key: "search-milvus",
                            })
                          }
                          className="hover:cursor-pointer hover:text-green-600"
                          size={"14px"}
                        />
                      </div>
                    )}
                  </h5>

                  <p className="text-[10px] xl:text-xs text-gray-400 font-normal">
                    Batch Size
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (!key) return;

    const instance = postProcessingInstanceData.filter(
      (instance) => instance?.asgName === key.id
    );

    if (instance?.length) {
      return (
        <div key={index} className="w-full min-w-[400px] relative">
          <div className="flex items-center">
            <div className="w-full border-border bg-white border rounded-xl shadow-lg py-2 xl:py-4 px-3 xl:px-6 transition-all duration-300 hover:shadow-xl">
              {postProcessingInstanceLoading ? (
                <div className="flex flex-col gap-2">
                  <h4 className="text-xs xl:text-sm text-gray-600 font-semibold">
                    {key.label}
                  </h4>
                  <SkeletonWrapper width="100%" height="100%" count="1" />
                </div>
              ) : (
                <>
                  <div className="flex gap-3 justify-between items-center mb-4">
                    <h4 className="text-xs xl:text-sm whitespace-nowrap text-[#777777] font-semibold">
                      {key.label}
                    </h4>

                    <div className="flex gap-1 xl:gap-2 items-center">
                      {!processingData?.data || batchNStatusLoading ? (
                        <SkeletonWrapper
                          width="100px"
                          height="30px"
                          count={1}
                          skeletonClass="rounded-full"
                        />
                      ) : (
                        <div className="flex gap-1 items-center text-sm text-muted-foreground">
                          Status: ({" "}
                          {processingData?.data?.[key.processingKey]?.status ===
                          "true"
                            ? "Active"
                            : "InActive"}
                          <Switch
                            checked={
                              processingData?.data?.[key.processingKey]
                                ?.status === "true"
                            }
                            onCheckedChange={(checked) => {
                              handleUpdateStatus({
                                value: checked,
                                key: key.processingKey,
                              });
                            }}
                            className="data-[state=checked]:bg-green-600"
                          />
                          )
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="grid gap-3 grid-cols-3">
                    <div className="relative border-r border-border">
                      <h5 className="text-lg xl:text-2xl font-bold text-gray-800">
                        {!processingData?.data || processingLoading ? (
                          <SkeletonWrapper
                            width="90px"
                            height="30px"
                            count={1}
                          />
                        ) : (
                          processingData?.data &&
                          processingData?.data[key.processingKey]?.counts
                        )}
                      </h5>
                      <p className="text-[10px] xl:text-xs text-gray-400 font-normal">
                        Remaining
                      </p>
                    </div>
                    <div className="relative border-r border-border">
                      <h5 className="text-lg xl:text-2xl font-bold text-red-600">
                        {!processingData?.data || processingLoading ? (
                          <SkeletonWrapper
                            width="90px"
                            height="30px"
                            count={1}
                          />
                        ) : (
                          `${calculateAverageFailurePercentage(
                            instance[0]?.inServiceInstances
                          )}
                        %`
                        )}
                      </h5>
                      <p className="text-[10px] xl:text-xs text-gray-400 font-normal">
                        Failure Rate
                      </p>
                    </div>
                    <div className="relative">
                      <h5 className="text-lg xl:text-2xl font-bold">
                        {!processingData?.data ||
                        processingLoading ||
                        batchNStatusLoading ? (
                          <SkeletonWrapper
                            width="70px"
                            height="30px"
                            count={1}
                          />
                        ) : (
                          <div className="flex gap-2 items-center">
                            {processingData?.data &&
                              processingData?.data[key.processingKey]
                                ?.batch_size}
                            <Pencil
                              onClick={() =>
                                setBatchModalOpen({
                                  isOpen: true,
                                  instance: instance?.[0],
                                  value: processingData?.data
                                    ? processingData?.data[key.processingKey]
                                        ?.batch_size
                                    : 0,
                                  key: key.processingKey,
                                })
                              }
                              className="hover:cursor-pointer hover:text-green-600"
                              size={"14px"}
                            />
                          </div>
                        )}
                      </h5>

                      <p className="text-[10px] xl:text-xs text-gray-400 font-normal">
                        Batch Size
                      </p>
                    </div>
                  </div>
                  <Accordion
                    type="single"
                    collapsible
                    value={openInstances[instance[0]?.asgName] || false}
                    className="w-full"
                  >
                    <AccordionItem
                      className="border-0"
                      value={openInstances[instance[0]?.asgName]}
                    >
                      <div className="border-t border-border mt-3 pt-3">
                        <AccordionTrigger
                          className="justify-start hover:no-underline pt-0 pb-0"
                          onClick={() =>
                            handleAccordionClick(instance[0]?.asgName)
                          }
                        >
                          <div className="flex justify-between w-full items-center gap-4">
                            <h4 className="text-base font-semibold text-black">
                              Instances (
                              {instance[0]?.inServiceInstances?.length})
                            </h4>
                            {instanceLoading ||
                            batchNStatusLoading ||
                            processingLoading ? (
                              <Loader size="sm" />
                            ) : (
                              <p
                                onClick={(e) => {
                                  e?.stopPropagation();
                                  setInstanceModalOpen({
                                    instance: instance?.[0],
                                    isOpen: true,
                                    value: [instance?.[0]?.instancesInService],
                                  });
                                }}
                                className="text-green-600 hover:cursor-pointer flex items-center gap-1"
                              >
                                <Pencil size={"14px"} />
                                Edit Instances
                              </p>
                            )}
                          </div>
                        </AccordionTrigger>
                        <AccordionContent>
                          <div className="flex flex-col gap-3 mt-3">
                            {instance[0]?.inServiceInstances?.map((item) =>
                              renderSingleInstance(item, key.label)
                            )}
                          </div>
                        </AccordionContent>
                      </div>
                    </AccordionItem>
                  </Accordion>
                </>
              )}
            </div>
          </div>
        </div>
      );
    }
  };

  const customComp = ({ data }) => {
    return (
      <>
        <Handle type="target" position={data.key.inputPoint} />
        <Handle id="a" type="target" position={Position.Right} />
        <Handle id="c" type="target" position={Position.Left} />
        {renderInstanceCard(data.key, data.index)}
        <Handle id="a" type="source" position={data.key.outputPoint} />
        <Handle type="source" position={Position.Right} id="b" />
      </>
    );
  };

  const nodeTypes = useMemo(
    () => ({
      selectorNode: customComp,
    }),
    [
      postProcessingInstanceData,
      processingData,
      processingLoading,
      openInstances,
    ]
  );

  useEffect(() => {
    dispatch(getPostProcessingInstances());
    dispatch(getPostProcessing());
    dispatch(getProcessingData());
  }, [dispatch]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      dispatch(getPostProcessingInstances());
    }, 10000);

    return () => clearInterval(intervalId);
  }, [dispatch]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      dispatch(getProcessingData());
    }, 3000);

    return () => clearInterval(intervalId);
  }, [dispatch]);

  const onNodesChange = useCallback(
    (changes) => {
      setNodes((nds) => applyNodeChanges(changes, nds));
    },
    [setNodes]
  );

  const handleUpdateInstance = async () => {
    const response = dispatch(
      updateInstanceCapacity({
        asgName: instanceModalOpen.instance?.asgName,
        desiredCapacity: instanceModalOpen.value?.at(0),
      })
    );

    if (response.payload) {
      setInstanceModalOpen({
        ...instanceModalOpen,
        isOpen: false,
      });

      const storedData =
        JSON.parse(localStorage.getItem("instanceCounts")) || {};

      storedData[response.payload.asgName] = response.payload.desiredCapacity;
      localStorage.setItem("instanceCounts", JSON.stringify(storedData));

      setTimeout(() => {
        dispatch(getPostProcessingInstances());
      }, 5000);
    }
  };

  const handleAccordionClick = (itemId) => {
    setOpenInstances((prevState) => ({
      ...prevState,
      [itemId]: !prevState[itemId],
    }));
  };

  const renderSingleInstance = (instance, label) => {
    const processingCount =
      processingData?.instance_failure_rate &&
      processingData?.instance_failure_rate[instance.InstanceId]
        ? JSON.parse(processingData?.instance_failure_rate[instance.InstanceId])
        : {};

    return (
      <div
        key={instance.InstanceId}
        className="border border-border rounded-lg p-2 bg-gray-50 text-sm flex flex-col"
      >
        <div className="mb-2 pb-2 flex items-center justify-start gap-2 border-b border-border">
          <Server className="w-4 h-4" />
          {instance.InstanceId} ({instance.InstanceType})
        </div>

        <div className="w-full text-xs grid grid-cols-4 gap-2 items-end">
          <div className="border-r border-border">
            {!processingData.data ? (
              <SkeletonWrapper
                className="mt-2"
                width="80px"
                height="12px"
                count={1}
              />
            ) : (
              <p className="text-blue-600">
                {(processingCount?.failure || 0) +
                  (processingCount?.success || 0) || "0"}
              </p>
            )}
            Total
          </div>
          <div className="border-r border-border">
            {!processingData.data ? (
              <SkeletonWrapper
                className="mt-2"
                width="80px"
                height="12px"
                count={1}
              />
            ) : (
              <p className="text-red-600">{processingCount?.failure || "0"}</p>
            )}
            Failure
          </div>
          <div className="border-r border-border">
            {!processingData.data ? (
              <SkeletonWrapper
                className="mt-2"
                width="80px"
                height="12px"
                count={1}
              />
            ) : (
              <p className="text-green-600">
                {processingCount?.success || "0"}
              </p>
            )}
            Success
          </div>
          <div>
            <p className="text-red-600">
              {!processingData.data ? (
                <SkeletonWrapper width="80px" height="20px" count={1} />
              ) : processingCount?.failure_rate ? (
                `${formateValue(processingCount?.failure_rate)}%`
              ) : (
                "0"
              )}
            </p>
            FailureRate
          </div>
        </div>

        <div className="w-full mt-2">
          {label === "Deepfake" && processingCount?.predictions && (
            <TooltipWrapper
              component={
                <div className="flex items-center justify-start gap-1 text-blue-600">
                  <TrendingUpDownIcon className="w-4 h-4" />
                  <p>Predications</p>
                  <Info className="w-3 h-3" />
                </div>
              }
              text={
                <p className="whitespace-pre">
                  {JSON.stringify(
                    processingCount?.predictions,
                    (key, value) => value,
                    2
                  )
                    .replace(/{/g, "{\n")
                    .replace(/,/g, ",\n")}
                </p>
              }
            />
          )}
        </div>
      </div>
    );
  };

  const calculateAverageFailurePercentage = (instances) => {
    if (
      !instances ||
      !instances.length ||
      !processingData?.instance_failure_rate
    )
      return 0;

    let sum = 0;
    for (let i = 0; i < instances.length; i++) {
      sum += Number(
        processingData?.instance_failure_rate &&
          processingData?.instance_failure_rate[instances[i].InstanceId]
          ? JSON.parse(
              processingData?.instance_failure_rate[instances[i].InstanceId]
            ).failure_rate
          : 0
      );
    }

    const avg = sum > 0 ? sum / instances.length : sum;

    return avg.toFixed(2);
  };

  const defaultKeys = {
    "frame-download": "sr-post-processing-frame-download",
    deepfake: "sr-post-processing-deepfake",
    classification: "sr-post-processing-classification",
    "threat-level": "sr-post-processing-threat-level",
    "search-milvus": "sr-preprocessing-search-milvus",
  };

  const handleUpdateStatus = ({ value, key }) => {
    if (!key) return;

    const data = dispatch(
      updateBatchNStatus({
        key: defaultKeys[key],
        type: "status",
        value: value == false ? "false" : "true",
      })
    );

    if (data) {
      const updatedData = Object.entries(processingData.data).reduce(
        (acc, item) => {
          if (item.at(0) === key) {
            acc[item.at(0)] = {
              ...item.at(1),
              status: value == false ? "false" : "true",
            };
          } else {
            acc[item.at(0)] = { ...item.at(1) };
          }
          return acc;
        },
        {}
      );

      setProcessData({ ...processingData, data: updatedData });
    }
  };

  const handleUpdateBatch = () => {
    if (!batchModalOpen.key) return;

    const data = dispatch(
      updateBatchNStatus({
        key: defaultKeys[batchModalOpen.key],
        type: "batch-size",
        value: batchModalOpen.value,
      })
    );

    if (data) {
      const updatedData = Object.entries(processingData.data).reduce(
        (acc, item) => {
          if (item.at(0) === batchModalOpen.key) {
            acc[item.at(0)] = {
              ...item.at(1),
              batch_size: batchModalOpen.value,
            };
          } else {
            acc[item.at(0)] = { ...item.at(1) };
          }
          return acc;
        },
        {}
      );

      setProcessData({ ...processingData, data: updatedData });
    }

    setBatchModalOpen({
      ...batchModalOpen,
      isOpen: false,
    });
  };

  const result = postProcessingData.graph_data
    ? Object.entries(postProcessingData.graph_data).reduce(
        (acc, [date, data]) => {
          const categories = {};

          for (const [key, value] of Object.entries(data)) {
            const cleanedLabel = key
              .replace("sr-post-processing-", "")
              .replace(
                "sr-preprocessing-search-results:",
                "preprocessing search results "
              )
              .replace("sr-preprocessing-search-milvus", " Milvus Search");
            categories[cleanedLabel] = value || 0;
          }

          // // Calculate the total
          // categories.total = Object.values(categories).reduce(
          //   (sum, value) => sum + value,
          //   0
          // );

          acc[date] = categories;

          return acc;
        },
        {}
      )
    : {};

  const transformedData = transformData(result);

  const startDate = transformedData.length > 0 ? transformedData[0].date : "";
  const endDate =
    transformedData.length > 0
      ? transformedData[transformedData.length - 1].date
      : "";
  const formattedStartDate = startDate
    ? formatDate(startDate, "d MMMM yyyy")
    : "";
  const formattedEndDate = endDate ? formatDate(endDate, "d MMMM yyyy") : "";

  return (
    <div className="w-full flex flex-col gap-8 mt-4">
      <div className="text-2xl font-bold">Post Processing</div>

      {!Object.keys(result).length ? (
        <SkeletonWrapper height="440px" width="100%" count={1} />
      ) : (
        <Charts
          title={`Daily Counts (${formattedStartDate} - ${formattedEndDate})`}
          data={result}
          postProcessingTitle={true}
          hideTitle
        />
      )}

      {!Object.keys(processingInstanceHistory).length ? (
        <SkeletonWrapper height="440px" width="100%" count={1} />
      ) : (
        <Charts
          data={processingInstanceHistory}
          title="Failure Rates"
          postProcessingTitle={true}
          hideTitle
          showNumber
          isPercent
          loading={processingLoading}
          reversed={true}
        />
      )}

      <div className=" gap-0 relative items-center">
        <div className="h-[600px] w-full border-border bg-slate-100 rounded-xl">
          {postProcessingInstanceData?.length > 0 &&
            !postProcessingInstanceLoading && (
              <ReactFlow
                fitView
                minZoom={0.8}
                maxZoom={2}
                edges={edge}
                nodes={nodes}
                onNodesChange={onNodesChange}
                nodeTypes={nodeTypes}
              >
                <Background />
              </ReactFlow>
            )}
        </div>
      </div>
      <Dialog
        open={instanceModalOpen.isOpen}
        onOpenChange={() =>
          setInstanceModalOpen({ isOpen: false, instance: null })
        }
      >
        <DialogContent className="w-[30%]">
          <div className="mt-4px-2 py-10 rounded-sm flex justify-center">
            <DualRangeSlider
              label={(value) => value}
              min={instanceModalOpen.instance?.minSize}
              max={instanceModalOpen.instance?.maxSize}
              step={1}
              value={instanceModalOpen.value}
              onValueChange={(e) =>
                setInstanceModalOpen({ ...instanceModalOpen, value: e })
              }
            />
          </div>
          <Button onClick={handleUpdateInstance} className="w-20">
            Update
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog
        open={batchModalOpen.isOpen}
        onOpenChange={() =>
          setBatchModalOpen({ isOpen: false, instance: null, value: 0 })
        }
      >
        <DialogContent className="w-[30%]">
          <div className="mt-4px-2 py-10 rounded-sm flex justify-center">
            <Input
              type="number"
              value={batchModalOpen.value}
              onChange={(e) =>
                setBatchModalOpen({ ...batchModalOpen, value: e.target.value })
              }
            />
          </div>
          <Button
            onClick={handleUpdateBatch}
            disabled={!batchModalOpen.value}
            className="w-20"
          >
            Update
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PostProcessingNew;
